var $ = require('jquery');
// var imagesLoaded = require('imagesloaded');
// var TweenLite = require('gsap');
// var scrollTo = require('../../node_modules/gsap/src/uncompressed/plugins/ScrollToPlugin.js');

var Hammer = require('hammerjs');

// var slidesArray = require('../assets/slides.json');

window.app = {
	W: $(window).width(),
	H: $(window).height(),
	sliders: [],
	interval: [],

	init: function() {
		// this.checkMenuItems();

		var _this = this;

		$.each($('.slider'), function(index, val) {
			var slider = new app.slider($(val));
			app.sliders.push(slider);
			slider.init();
		});

		this.setupEvents();
		this.setHeaderHeight()

		// this.preload([

		// ]);

		app.startSliderInterval();
	},

	setupEvents: function() {
		var _this = this;

		$('.slider-nav').on('click', 'a', function(event) {
			event.preventDefault();
			var elementIndex = $(this).parent().index();

			$.each(app.sliders, function(index, val) {
				var obj = app.sliders[index];
				obj.move(elementIndex);
			});

			clearInterval(app.interval);
			app.startSliderInterval();
		});

		$('.mobileNav-trigger a').click(function(event) {
			event.preventDefault();
			_this.toggleMobileNav();
		});

		$('.page-content-mask').click(function(event) {
			event.preventDefault();
			_this.toggleMobileNav();
		});

		$(window).on('resize', function(event) {
			event.preventDefault();
			_this.onResize();
		});
	},

	startSliderInterval: function() {
		app.interval = setInterval(function() {
			$.each(app.sliders, function(index, val) {
				var obj = app.sliders[index];
				obj.moveRight();
			});
		}, 5000)
	},

	onResize: function() {
		app.W = $(window).width()
		app.H = $(window).height()

		this.setHeaderHeight()
	},

	// scrollToTop: function() {
	// 	if ($(window).scrollTop() > 0) {
	// 		this.scrollTo({
	// 			pos: 0, 
	// 			speed: 0.4
	// 		});
	// 	}
	// },

	setHeaderHeight: function() {
		var futureHeight = $('.page-content').outerHeight(),
			$header = $('.header')

		if (app.W > 768 && $('.header-inner').outerHeight() < app.H) {
			$header.addClass('fixed');
		} else {
			$header.removeClass('fixed')
		}

		if (app.W > 1024 && futureHeight > app.H ) {
			$header.css('min-height', futureHeight + 'px' )
		} else {
			$header.css('min-height', '')
		}

	},

	// scrollTo: function(params) {

	// 	if (params.pos == undefined) {params.pos == 0};
	// 	if (params.speed == undefined) {params.speed == 0.3};

	// 	TweenLite.to( window, params.speed, {
	// 		scrollTo: {
	// 			y: params.pos
	// 		},
	// 		ease: Cubic.linear, 
	// 		onComplete: function(){
	// 			if (params.after) {
	// 				params.after();
	// 			}
	// 		}
	// 	});
	// },

	toggleMobileNav: function() {
		var $header = $('header'),
			$nav = $('.mobileNav-trigger'),
			$pageContent = $('.page-content');

		if ($header.hasClass('active')) {
			$header.removeClass('active');
			$pageContent.removeClass('mask');
			$header.removeClass('active');
			$nav.removeClass('active');
		} else {
			$header.addClass('active');
			$pageContent.addClass('mask');
			$nav.addClass('active');
		}

	},

	preload: function(images) {

		var $body = $('body'),
			_this = this;

		if (images) {
			$.each(images, function(index, val) {
				$body.find('.footer').append('<img class="preloading" src=" ' + val +' ">');
			});
		}

		imagesLoaded( 'body', { background: true }, function() {
			$body.find('.preloading').each(function(index, el) {
				$(this).remove();
			});

			setTimeout(function() {
				$body.removeClass('loading');
			}, 400);

			app.startSliderInterval();
		});
	},

	slider: function($slider) {
		var sliderIndex = 0,
			sliderMax = $slider.find('.slider-holder .slider-box').length,
			pxToMove = $slider.find('.slider-holder-outer').width(),
			isMoved = false,
			interval,
			previousActive;

		function setupEvents() {
			
			var mc = new Hammer($slider.get(0));

			mc.on("swipeleft", function(ev) {
				moveRight();
			});

			mc.on("swiperight", function(ev) {
				moveLeft();
			});

			$slider.on( "swipeleft",  function(event){
				moveLeft();
			});

			$slider.on( "swiperight",  function(event){
				moveRight();
			});

			$slider.on('parentResized', function(event) {
				event.preventDefault();
				pxToMove = $slider.find('.slider-holder-outer').width();

				setSliderBoxWidth()
				move(0);
			});

			$(window).on('resize', function(event) {
				$slider.trigger('parentResized');
			});
		}

		function setSliderBoxWidth() {
			var parentWidth = $slider.find('.slider-holder-outer').width();



			// $.each($slider.find('.slider-cta'), function(index, val) {
			// 	if ($(val).outerHeight() > 42) {
			// 		$(val).css('min-width', '240px');
			// 	}
			// });

			$.each($slider.find('.slider-box'), function(index, val) {
				 $(val).css('width', parentWidth + "px");
			});
		}

		function move(thisIndex) {

			$slider.find('.slider-holder').each(function(index, el) {
				var px;

				if (!isNaN(thisIndex)) {

					sliderIndex = thisIndex;

					$('body').find('.slider-nav li').removeClass('active');
					$('body').find('.slider-nav li:nth-child('+(sliderIndex+1)+')').addClass('active');

					previousActive = $('body').find('.slider-holder li.active');

					$('body').find('.slider-holder li').removeClass('active');
					$('body').find('.slider-holder li:nth-child('+(sliderIndex+1)+')').addClass('active');

					// px = (-sliderIndex * pxToMove);

					// $(el).css({
					// 	'-moz-transform': 'translateX('+px+'px)',
					// 	'-webkit-transform': 'translateX('+px+'px)',
					// 	'-o-transform': 'translateX('+px+'px)',
					// 	'-ms-transform': 'translateX('+px+'px)',
					// 	'transform': 'translateX('+px+'px)'
					// });
				};
			});
		}

		function moveLeft() {
			if (sliderIndex > 0) {
				sliderIndex--;
				move(sliderIndex);
			} else if (sliderIndex < 1) {
				sliderIndex = sliderMax - 1;
				move(sliderIndex)
			}
		}

		function moveRight() {
			if (sliderIndex < sliderMax - 1) {
				sliderIndex++;
				move(sliderIndex);
			} else if (sliderIndex == sliderMax - 1) {
				sliderIndex = 0;
				move(0);
			}
		}

		function undelegateEvents()  {
			$slider.unbind('parentResized');
		}

		function init() {
			setupEvents();
			setSliderBoxWidth();
			$slider.find('.slider-holder .slider-box').first().addClass('active');
		}

		return {
			init: init,
			undelegateEvents: undelegateEvents,
			move: move,
			moveRight: moveRight
		}
	}
};

$(document).ready(function() {
	// need to do immadietly
	if ($('.header-sidenav > ul > li').hasClass('current-menu-item')) {
			$('.header-sidenav > ul > li .sub-menu li').removeClass('current-menu-item')
		}

	window.app.init();
});